import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'crm/tMerchantCommission',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'crm/tMerchantCommission/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'crm/tMerchantCommission',
    method: 'put',
    data
  })
}

export function modifyStatus(data) {
  return request({
    url: '/crm/tMerchantCommission/modifyCommStatus',
    method: 'post',
    data
  })
}
export function audit(data) {
  return request({
    url: '/crm/tMerchantCommission/commApplyAudit',
    method: 'get',
    params: data
  })
}

export function queryCommStat(data) {
  return request({
    url: '/crm/tMerchantCommissionOrder/queryCommStat',
    method: 'get',
    params: data
  })
}

// 批量出佣
export function batchCommApplyAudit(data) {
  return request({
    url: '/crm/tMerchantCommission/batchCommApplyAudit',
    method: 'post',
    data,
  })
}
// 批量出金
export function batchAuditRquest(data) {
  return request({
    url: '/crm/tCustDepositWithdraw/batchAudit',
    method: 'post',
    data,
  })
}

/**
 * 请求参数：id - 出佣申请id
 payPic - 支付凭证
 payStatus - 支付状态 1-待支付 2-支付中 3-支付完成 4-支付失败
 * @param data
 * @returns {*}
 */
export function modifyCommissionPayStatus(data) {
  return request({
    url: '/crm/tMerchantCommission/modifyPayStatus',
    method: 'post',
    data,
  })
}
